import {
  type ActionFunctionArgs,
  type LoaderFunctionArgs,
  type MetaFunction,
  redirect,
} from '@remix-run/node'
import {
  Link,
  useActionData,
  useLoaderData,
  useSearchParams,
} from '@remix-run/react'
import clsx from 'clsx'
import Cookies from 'js-cookie'
import { LucideAlertCircle, LucideCircleCheckBig } from 'lucide-react'
import { useEffect, useState } from 'react'
import { validationError } from 'remix-validated-form'
import { OrganizationLogo } from '~/components'
import { Alert, Card } from '~/components/ui'
import { activityHelper, authenticateUser } from '~/mongo.server'
import {
  env,
  getCookieToken,
  getOrganizationSlug,
  JWT_TOKEN_VERSION,
  parseTokenUser,
  setCookieTokenHeader,
  signToken,
  updateUserLastLogin,
} from '~/utils.server'
import { EmailLoginForm, emailLoginValidator } from './EmailLoginForm'
import { PasswordLoginForm, passwordLoginValidator } from './PasswordLoginForm'
import { getRedirectParam, sendLoginLink } from './utils'

const LOGIN_FORM_PREF = 'as_login_form_pref'

export async function loader({ request }: LoaderFunctionArgs) {
  const redirectParam = getRedirectParam(request)
  const withEmail = request.headers
    .get('Cookie')
    ?.includes(`${LOGIN_FORM_PREF}=true`)
  const actionRoute = `/login${redirectParam}`
  const token = await getCookieToken(request)
  if (token === null) {
    return { actionRoute, withEmail }
  } else {
    const user = parseTokenUser(token)
    if (user === null) {
      return { actionRoute, withEmail }
    } else {
      // if (user!.group !== UserGroup.viewer)
      return redirect('/admin')
    }
  }
}

export const meta: MetaFunction = () => [{ title: 'Login - Associate' }]

export async function action({ request }: ActionFunctionArgs) {
  const url = new URL(request.url)
  const formData = await request.formData()

  if (formData.get('variant') === 'password') {
    // Login with password
    const result = await passwordLoginValidator.validate(formData)
    if (result.error) {
      return validationError(result.error)
    }

    const { email, password } = result.data
    console.info('Log in with email', email)
    const organizationSlug = getOrganizationSlug(request)

    const tokenUser = await authenticateUser(
      { email, 'organization.slug': organizationSlug },
      password
    )

    if (!tokenUser) {
      console.info('Authenticate user failed')
      return validationError(
        {
          fieldErrors: {
            password: 'Invalid email or password',
          },
          formId: result.formId,
        },
        result.data
      )
    }

    // Duplicated code with auth redirect
    const loginToken = signToken(
      {
        ...tokenUser,
        version: JWT_TOKEN_VERSION,
      },
      env.LOGIN_TOKEN_SECRET,
      {
        expiresIn: '30d',
      }
    )
    const redirectUrl = url.searchParams.get('redirect')
      ? url.searchParams!.get('redirect')!
      : '/'

    await activityHelper(request)
      .source('admin')
      .type('login')
      .related('user', tokenUser)
      .write()

    await updateUserLastLogin(tokenUser._id)

    return redirect(redirectUrl, {
      headers: await setCookieTokenHeader(loginToken),
    })
  } else {
    // Send login email
    const result = await emailLoginValidator.validate(formData)
    if (result.error) {
      return validationError(result.error)
    }

    const { email } = result.data
    try {
      const userExists = await sendLoginLink(
        email!.toString().trim().toLowerCase(),
        request
      )
      if (!userExists) {
        return {
          success: false,
          message:
            'Your email was not found for this organization. Please contact your organization.',
        }
      }
    } catch (error) {
      console.error('Error sending login link', error)
      return {
        success: false,
        message:
          'There was a problem sending login link. Please contact associate@resimplifi.com',
      }
    }
    return {
      success: true,
      message:
        'You will receive a login link if you belong to this organization. The login link will expire in 15 minutes.',
    }
  }
}

export default function LoginRoute() {
  const { actionRoute, withEmail: initialWithEmail } =
    useLoaderData<typeof loader>()
  const actionData = useActionData<{
    success: boolean
    message: string
    formId?: string
  }>()
  const [searchParams] = useSearchParams()
  const message = searchParams.get('message')
  const messageType = searchParams.get('type')
  const [withEmail, setWithEmail] = useState(() => {
    return searchParams.has('email') || initialWithEmail
  })

  useEffect(() => {
    if (withEmail) {
      Cookies.set(LOGIN_FORM_PREF, 'true', { expires: 60 * 60 * 24 * 400 })
    } else {
      Cookies.remove(LOGIN_FORM_PREF)
    }
  }, [withEmail])

  return (
    <div className="mb-40 w-full max-w-md">
      <Card>
        <Card.Header className="justify-center">
          <OrganizationLogo className="my-4 h-10 w-auto" tabIndex={-1} />
        </Card.Header>
        <Card.Content>
          <h1 className="mb-8 text-center text-2xl leading-9 tracking-tight text-foreground">
            Sign in to your account
          </h1>
          {message && (
            <Alert
              variant={messageType === 'success' ? 'success' : 'destructive'}
              className="mb-4 mt-4">
              {messageType === 'success' ? (
                <LucideCircleCheckBig className="h-4 w-4" />
              ) : (
                <LucideAlertCircle className="h-4 w-4" />
              )}
              <Alert.Title>
                {messageType === 'success' ? 'Success' : 'Error'}
              </Alert.Title>
              <Alert.Description>{message}</Alert.Description>
            </Alert>
          )}
          {actionData && !actionData?.formId ? (
            <div className="mb-6 text-center">
              <p className={clsx(!actionData!.success && 'text-destructive')}>
                {actionData?.message}
              </p>
            </div>
          ) : (
            <>
              {withEmail ? (
                <EmailLoginForm actionRoute={actionRoute} />
              ) : (
                <PasswordLoginForm actionRoute={actionRoute} />
              )}
              <div className="mt-4 flex justify-between">
                <button
                  onClick={() => setWithEmail(!withEmail)}
                  className="text-sm text-foreground underline">
                  {withEmail ? 'Sign in with password' : 'Sign in with email'}
                </button>
                <Link
                  to="/forgot-password"
                  className="text-sm text-foreground underline">
                  Forgot Password
                </Link>
              </div>
            </>
          )}
        </Card.Content>
      </Card>
    </div>
  )
}
