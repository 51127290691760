import { withZod } from '@remix-validated-form/with-zod'
import { ValidatedForm, useField, useIsSubmitting } from 'remix-validated-form'
import { z } from 'zod'
import { LoadingCircle } from '~/components'
import { Button, Input, Label } from '~/components/ui'

export const emailLoginValidator = withZod(
  z.object({
    variant: z.string(),
    email: z.string().email('Enter a valid email').toLowerCase(),
  })
)

export function EmailLoginForm({ actionRoute }: { actionRoute: string }) {
  const formId = 'email-login-form'
  const { error } = useField('email', { formId })
  const isSubmitting = useIsSubmitting(formId)

  return (
    <ValidatedForm
      method="post"
      action={actionRoute}
      id={formId}
      validator={emailLoginValidator}>
      <input type="hidden" name="variant" value="email" />
      <div className="mb-4">
        <Label
          htmlFor="email"
          className="block text-sm font-medium leading-6 text-foreground">
          Email address
        </Label>
        <div className="mt-2">
          <Input id="email" name="email" type="email" autoComplete="email" />
        </div>
        {error && <p className="mt-2 text-sm text-red-500">{error}</p>}
      </div>

      <Button
        className="button flex w-full justify-center"
        disabled={isSubmitting}
        type="submit">
        {isSubmitting ? 'Sending' : 'Send Login Link'}
        {isSubmitting && <LoadingCircle className="mx-2 h-5 w-5" />}
      </Button>
    </ValidatedForm>
  )
}
